import { Link } from "react-router-dom"
export default function InstaCalSupportPage() {
    return (
        <div style={{padding: '8px'}}>
            <h1>Frequently Asked Questions</h1>

            <h2>1. What is InstaCal?</h2>
            <p>InstaCal is an AI-powered calorie tracking app that helps you monitor your daily calorie intake, track your meals, and achieve your fitness goals. The app uses advanced algorithms to analyze your food choices and provide personalized recommendations.</p>

            <h2>2. How do I get support if I have issues with InstaCal?</h2>
            <p>If you encounter any issues or have questions about using InstaCal, you can contact our support team via email at <a href="mailto:support@codenurturers.com">support@codenurturers.com</a>. We’re here to help!</p>

            <h2>3. How does InstaCal track my calories?</h2>
            <p>InstaCal allows you to log your meals manually or by scanning barcodes. The app uses its AI technology to automatically estimate the calorie content of your food and drink based on its extensive database.</p>

            <h2>4. Can InstaCal sync with other fitness apps?</h2>
            <p>Yes, InstaCal can sync with popular fitness apps like Apple Health, Google Fit, and Fitbit. This allows you to integrate your calorie tracking with your overall fitness routine.</p>

            <h2>5. How do I log a meal on InstaCal?</h2>
            <p>To log a meal, open the app, click on the "Add Meal" button, and enter the details of your food. You can search for items in the database, scan barcodes, or manually input the nutritional information.</p>

            <h2>6. Does InstaCal offer personalized diet plans?</h2>
            <p>Yes, InstaCal provides personalized diet plans based on your health goals, dietary preferences, and calorie needs. The AI analyzes your eating habits and suggests adjustments to help you stay on track.</p>

            <h2>7. Can I track macronutrients with InstaCal?</h2>
            <p>Absolutely! InstaCal allows you to track not just calories, but also macronutrients like proteins, fats, and carbohydrates. You can view detailed breakdowns of your nutrient intake for each meal or day.</p>

            <h2>8. How accurate is the AI in estimating calories?</h2>
            <p>The AI in InstaCal is designed to be highly accurate, using a vast database of food items and nutritional information. However, for the most precise results, it’s recommended to double-check manually entered data.</p>

            <h2>9. Is InstaCal free to use?</h2>
            <p>No InstaCal only offers a paid version.</p>

            <h2>10. How does InstaCal handle my data?</h2>
            <p>InstaCal is committed to protecting your privacy. Your data is encrypted and stored securely, and we do not share your information with third parties without your consent. You can review our full <Link to="/instacal/privacy-policy">privacy-policy</Link> for more details.</p>

        </div>
    )
}