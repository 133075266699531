export default function InstaCalMarketingPage(){
    return (
        <div style={{padding: '8px'}}>
            <h1>InstaCal Privacy Policy</h1>
            <h2>1. Introduction</h2>
            <p>Welcome to InstaCal! We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application and services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>

            <h2>2. Information We Collect</h2>
            <p>We may collect and process the following types of information:</p>
            <ul>
                <li><strong>Personal Data:</strong> This includes information such as your name, email address, phone number, and other identifiers you provide during registration or while using InstaCal.</li>
                <li><strong>Usage Data:</strong> Information about how you use our app, including your activity, preferences, and interactions with InstaCal.</li>
                <li><strong>Device Information:</strong> Data about the device you use to access InstaCal, including IP address, browser type, operating system, and device identifiers.</li>
                <li><strong>Location Data:</strong> With your permission, we may collect and process location data to provide location-based services.</li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Provide, maintain, and improve InstaCal.</li>
                <li>Personalize your experience and deliver content tailored to your interests.</li>
                <li>Communicate with you about updates, promotions, and other information related to InstaCal.</li>
                <li>Analyze usage patterns to improve our app's functionality and user experience.</li>
                <li>Comply with legal obligations and protect our legal rights.</li>
            </ul>

            <h2>4. Sharing Your Information</h2>
            <p>We do not sell, trade, or rent your personal data to third parties. However, we may share your information with:</p>
            <ul>
                <li><strong>Service Providers:</strong> Third-party companies that help us operate, maintain, or improve InstaCal, such as hosting services, analytics providers, and customer support.</li>
                <li><strong>Legal Requirements:</strong> If required by law, we may disclose your information to government authorities or other third parties to comply with legal obligations or protect our rights.</li>
            </ul>

            <h2>5. Data Security</h2>
            <p>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, loss, or misuse. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

            <h2>6. Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access the personal data we hold about you.</li>
                <li>Request correction of inaccurate or incomplete data.</li>
                <li>Request the deletion of your personal data.</li>
                <li>Object to the processing of your data or request a restriction on its processing.</li>
                <li>Withdraw your consent at any time, where consent is the legal basis for processing your data.</li>
            </ul>
            <p>To exercise these rights, please contact us at <a href="mailto:support@codenurturers.com">support@codenurturers.com</a>.</p>

            <h2>7. Children's Privacy</h2>
            <p>InstaCal is not intended for use by individuals under the age of 13. We do not knowingly collect personal data from children under 13. If we become aware that we have inadvertently collected such data, we will take steps to delete it as soon as possible.</p>

            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised policy will take effect once posted. We encourage you to review this policy periodically to stay informed about how we protect your information.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
            <p>Email: <a href="mailto:support@codenurturers.com">support@codenurturers.com</a></p>
            {/* <p>Address: [Insert Physical Address]</p> */}
            {/* <p>Phone: [Insert Phone Number]</p> */}

            <p>This Privacy Policy is designed to comply with applicable privacy laws and regulations. By using InstaCal, you agree to the terms of this Privacy Policy.</p>
        </div>
    )
}