import { Stack, Typography } from "@mui/material";

export default function Calendly(){
    return (
        <Stack sx={{display: 'inline'}}>
            <a href="https://calendly.com/code-nurturers/30min">
                Book a 1:1 meeting
            </a>
            <Typography variant="subtitle"> with our CEO Jonathon</Typography>
        </Stack>
    )
}