import * as React from 'react';
import { 
  Box, Button, Card, CardActions, CardContent, 
  Chip, Container, Divider, Grid,
  Stack, Typography 
} from "@mui/material";
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const tiers = [
  {
    title: 'Custom Platform',
    basePrice: 2599,
    monthlyPrice: 299,
    description: [
      'Marketing website',
      'APIs & Databases',
      'Cloud Hosting',
      '2 Integrations with your tools',
      'Discord server support'
    ],
    buttonText: 'Free consultation',
    buttonVariant: 'outlined',
    progressionHref: "https://calendly.com/code-nurturers/30min"
  },
  {
    title: 'Professional Platform',
    subheader: 'Recommended',
    basePrice: 4999,
    monthlyPrice: 349,
    description: [
      'AI Lead Gen',
      'AI Customer Support Chat',
      'Customer Appointments',
      'Stripe Payment Integration',
      '5 integrations with your tools',
    ],
    buttonText: 'Free Sales Call',
    buttonVariant: 'contained',
    progressionHref: "https://calendly.com/code-nurturers/30min"
  },
  {
    title: 'Enterprise',
    monthlyPrice: 0,
    description: [
      'Customized platform solutions',
      'Custom mobile applications',
      'Specialized AI integrations',
      'Same day support',
    ],
    buttonText: 'Contact our CEO',
    buttonVariant: 'outlined',
    progressionHref: "https://calendly.com/code-nurturers/30min"
  },
];

export default function PricingSection() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We offer different pricing tiers to meet your business' needs and allow you to upgrade as you grow.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <PricingCard tier={tier}/>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

function PricingCard(props){
  let {tier} = props
  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        border: tier.title === 'Professional' ? '1px solid' : undefined,
        borderColor:
          tier.title === 'Professional' ? 'primary.main' : undefined,
        background:
          tier.title === 'Professional'
            ? 'linear-gradient(#033363, #021F3B)'
            : undefined,
      }}
    >
      <CardContent>
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: tier.title === 'Professional' ? 'grey.100' : '',
          }}
        >
          <Typography component="h3" variant="h6">
            {tier.title}
          </Typography>
          {tier.title === 'Professional' && (
            <Chip
            //   icon={<AutoAwesomeIcon />}
              label={tier.subheader}
              size="small"
              sx={{
                background: (theme) =>
                  theme.palette.mode === 'light' ? '' : 'none',
                backgroundColor: 'primary.contrastText',
                '& .MuiChip-label': {
                  color: 'primary.dark',
                },
                '& .MuiChip-icon': {
                  color: 'primary.dark',
                },
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            color: tier.title === 'Professional' ? 'grey.50' : undefined,
          }}
        >
          {tier.title === 'Enterprise' ? 
            <Typography variant='subtitle'>Customized for your unique needs</Typography> 
            :
            <Stack sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography component="h3" variant="h2">
                ${tier.basePrice}
              </Typography>
              <div>
                <Typography component="h5" variant='h5'>
                  + ${tier.monthlyPrice}/month
                </Typography>
              </div>
              {/* <Typography variant="subtitle">One-time $199 startup cost</Typography> */}
            </Stack>
          }
          
        </Box>
        <Divider
          sx={{
            my: 2,
            opacity: 0.2,
            borderColor: 'grey.500',
          }}
        />
        {tier.description.map((line) => (
          <Box
            key={line}
            sx={{
              py: 1,
              display: 'flex',
              gap: 1.5,
              alignItems: 'center',
            }}
          >
            
            <Typography
              component="text"
              variant="subtitle2"
              sx={{
                color:
                  tier.title === 'Professional' ? 'grey.200' : undefined,
              }}
            >
              {line}
            </Typography>
          </Box>
        ))}
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant={tier.buttonVariant}
          component="a"
          href={tier.progressionHref}
          target="_blank"
        >
          {tier.buttonText}
        </Button>
      </CardActions>
    </Card>
  )
}