import {Link} from 'react-router-dom'
export default function InstaCalTerms() {
    return (
        <div style={{ padding: '8px' }}>
            <h1>Terms of Service</h1>

            <p><strong>Effective Date:</strong> September 9, 2024</p>

            <h2>1. Introduction</h2>
            <p>Welcome to InstaCal! By accessing or using InstaCal (the "App"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to all the terms and conditions, you are prohibited from using the App. InstaCal reserves the right to modify these Terms at any time, and your continued use of the App will signify your acceptance of any changes.</p>

            <h2>2. Use of the App</h2>
            <p>InstaCal grants you a non-exclusive, non-transferable, limited license to access and use the App for personal, non-commercial purposes. You agree not to:</p>
            <ul>
                <li>Copy, modify, or distribute any part of the App without prior written consent from InstaCal.</li>
                <li>Use the App for any unlawful or harmful activity, including but not limited to, the violation of any applicable laws or regulations.</li>
                <li>Interfere with the security, functionality, or accessibility of the App.</li>
            </ul>

            <h2>3. Account Registration</h2>
            <p>To use certain features of InstaCal, you may be required to create an account. You agree to provide accurate, complete information during the registration process and to update your information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

            <h2>4. Subscription and Payments</h2>
            <p>InstaCal offers different premium subscription services. By subscribing to a premium plan, you agree to pay the applicable fees. All payments are non-refundable unless required by applicable law. We reserve the right to change our subscription fees at any time with prior notice.</p>

            <h2>5. User-Generated Content</h2>
            <p>You may have the opportunity to submit content such as meal logs, reviews, or other materials ("User Content"). By submitting User Content, you grant InstaCal a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your User Content in connection with the App. You represent that you own or have the necessary rights to share any User Content you submit and that it does not violate any third-party rights.</p>

            <h2>6. Intellectual Property</h2>
            <p>All content available on the App, including but not limited to, text, graphics, logos, icons, and software, is the property of InstaCal or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any of the content without InstaCal’s express permission.</p>

            <h2>7. Privacy</h2>
            <p>Your use of the App is subject to InstaCal’s <Link to="/instacal/privacy-policy">Privacy Policy</Link>, which governs how we collect, use, and protect your personal data. By using the App, you agree to the terms of the Privacy Policy.</p>

            <h2>8. Termination</h2>
            <p>InstaCal reserves the right to terminate or suspend your account at any time, with or without notice, for any reason, including but not limited to, violation of these Terms. Upon termination, your right to use the App will immediately cease, and InstaCal may delete your account and related data.</p>

            <h2>9. Limitation of Liability</h2>
            <p>To the fullest extent permitted by law, InstaCal and its affiliates will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use or inability to use the App. InstaCal's total liability to you for any claims related to your use of the App will not exceed the amount paid by you to InstaCal during the 12 months preceding the claim.</p>

            <h2>10. Disclaimer of Warranties</h2>
            <p>The App is provided "as is" and "as available" without any warranties of any kind, either express or implied. InstaCal does not guarantee that the App will be error-free, uninterrupted, or secure. Your use of the App is at your sole risk.</p>

            <h2>11. Governing Law</h2>
            <p>These Terms are governed by and construed in accordance with the laws of the United States of America. Any legal action or proceeding arising under these Terms will be brought exclusively in the courts of United States of America, and you consent to the jurisdiction and venue of such courts.</p>

            <h2>12. Contact Us</h2>
            <p>If you have any questions or concerns regarding these Terms, please contact us at:</p>
            <p>Email: <a href="mailto:support@codenurturers.com">support@codenurturers.com</a></p>

        </div>
    )
}