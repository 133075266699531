import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Testimonials from '../components/Testimonials';
import Highlights from '../components/Highlights';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';
import Navbar from '../layout/Navbar';
import Hero from '../components/Hero';
export default function HomePage () {
    return (
        <>
            <CssBaseline />
            <Navbar mode="light"/>
            <Box sx={{ bgcolor: 'background.default' }}>
                
                <Hero/>
                {/* <Testimonials />
                <Divider /> */}
                <Highlights />
                <Divider />
                <Pricing />
                <Divider />
                <Footer />
            </Box>
        </>
    )
}